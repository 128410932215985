<script lang="ts" setup>
import feedbackImage from '../assets/img/feedback-bg.png'
import { FEEDBACK_LINK } from '../constants'

const {
  modelValue,
} = defineProps<{
  modelValue: boolean
}>()

const emit = defineEmits(['update:modelValue', 'redirect'])

const { width } = useWindowSize()

const dialog = computed({
  get() {
    return modelValue
  }, set(val) {
    emit('update:modelValue', val)
  },
})

function handleClick() {
  dialog.value = false
  emit('redirect')
}
</script>

<template>
  <b-modal
    v-model="dialog"
    title="JPool Feedback Hub"
    class="feedback-dialog"
    centered
    scrollable
    no-footer
    :class="{ 'modal-slide-up': width <= 650 }"
  >
    <div class="feedback-dialog-wrapper">
      <img :src="feedbackImage" alt="feedback image" class="feedback-image">
      <div class="feedback-text">
        Your opinion helps us improve.
        <br><br>
        Let us know what you liked or what we can do better!
      </div>

      <b-button :href="FEEDBACK_LINK" variant="primary" pill target="_blank" @click="handleClick">
        <span>Leave feedback</span>
      </b-button>
    </div>
  </b-modal>
</template>

<style lang="scss">
.feedback-dialog {
  @media (max-width: $breakpoint-xs) {
    .modal-dialog {
      height: 100%;
      max-width: 100%;
      width: 100%;
      margin: 0;
      align-items: flex-end;

      .modal-content {
        max-height: 70%;
        border-radius: $spacing-24 $spacing-24 0 0;
        padding: $spacing-24;
      }
    }
  }

  &.modal-slide-up {
    transform: translateY(100%);
    opacity: 0;
    transition:
      transform 0.1s ease-out,
      opacity 0.1s ease-out;
  }

  &.modal.show {
    transform: translateY(0);
    opacity: 1;
  }

  .modal-header {
    border: none;
    padding: 0 !important;
  }
  .modal-body {
    padding: $spacing-16 0 0 !important;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: $spacing-16;

    .feedback-image {
      width: 100%;
      height: auto;
      object-fit: contain;
    }

    .feedback-text {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .btn {
      width: 100%;
      span {
        position: relative;
        top: -1px;
      }
    }
  }
}
</style>
