export const TWITTER_URL = 'https://twitter.com/JPoolSolana'
export const TELEGRAM_ANNOUNCEMENT_URL = 'https://t.me/jpoolsolana'
export const DISCORD_URL = 'https://discord.gg/qR4BA9QXVR'
export const SVT_URL = 'https://svt.one'
export const TELEGRAM_URL = 'https://t.me/jpoolsolana'
export const TELEGRAM_OFFICIAL_URL = 'https://t.me/jpool_solana'
export const LINKEDIN_LINK = 'https://www.linkedin.com/company/jpoolsolana'
export const FAQ_LINK = 'https://docs.jpool.one/additional-information/faq'
export const MEDIAKIT_LINK = 'https://www.figma.com/design/FsniHnFpzwDrxeRzZdqxvJ/JPool-Media-Kit?node-id=2-448&t=czz4uyGdht1Ih60t-1'
export const SUPPORT_LINK = TELEGRAM_URL

// export const GTAG_ID = import.meta.env.VITE_GTAG_ID
export const GTM_ID = import.meta.env.VITE_GTM_ID
export const MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_ID
export const HOTJAR_ID = import.meta.env.VITE_HOTJAR_ID
