import type { PublicOrchestratedToast } from 'bootstrap-vue-next'
import { BToast, useToastController } from 'bootstrap-vue-next'
import attentionIcon from '~/assets/img/icons/attention-circle.svg?raw'
import dangerIcon from '~/assets/img/icons/danger-icon.svg?raw'
import checkIcon from '~/assets/img/icons/toast-check-icon.svg?raw'

export type ToastProps = {
  message?: string
  title?: string
  actions?: Array<{
    label: string
    color?: string
    target?: string
    class?: string
    href?: string
    onClick?: () => void
  }>
  noCloseButton
} & PublicOrchestratedToast

export function useToast() {
  const { show, remove } = useToastController()

  /**
   * value @type {number}
   * - `Infinity` — not auto close
   * - `1000` —  close after 1000 ms
   * progressProps
   * @type {Partial<ProgressProps>}
   * { variant: 'success' | 'info' | 'warning' | 'danger' }
   */
  function create({
    message = '',
    pos = 'bottom-center',
    variant = 'success',
    value = 5000,
    toastClass = '',
    noHoverPause = false,
    noCloseButton = false,
    actions = [],
    ...args
  }: Partial<ToastProps> = {}) {
    const iconMap: Record<string, string> = {
      success: checkIcon,
      info: attentionIcon,
      warning: attentionIcon,
      danger: dangerIcon,
    }

    const classMap: Record<string, string> = {
      success: 'toast-success',
      info: 'toast-info',
      warning: 'toast-warning',
      danger: 'toast-danger',
    }

    const props = {
      pos,
      value: Number(value) > 0 ? value : Infinity,
      toastClass: `${toastClass} ${classMap[variant!] || ''}`,
      noHoverPause,
      noCloseButton,
      ...args,
      title: '',
    }

    const toastInstance = show({
      props,
      component: h(BToast, null, {
        default: () =>
          h('div', { class: 'toast-wrapper' }, [
            h('div', { class: 'toast-content' }, [
              h('div', { class: 'toast-icon', innerHTML: iconMap[variant!] }),
              h('div', { class: 'toast-message', innerHTML: `${args.title ? `<div class="toast-title">${args.title}</div>` : ''} ${message}` }),
              !noCloseButton && h('yb', {
                class: 'btn-close',
                onClick: () => {
                  remove(toastInstance)
                },
              }),
            ]),
            actions.length > 0
            && h('div', { class: 'toast-actions' },
              actions.map(action =>
                h(
                  'a',
                  {
                    class: `toast-action ${action?.class || ''}`,
                    href: action?.href || '#',
                    target: action?.target || '_self',
                    style: { color: action?.color || 'inherit' },
                    onClick: (event) => {
                      if (action.onClick) {
                        event.preventDefault()
                        action.onClick()
                        remove(toastInstance)
                      }
                    },
                  },
                  action.label,
                ),
              ),
            ),
          ]),
      }),
    })

    return {
      dismiss: () => remove(toastInstance),
    }
  }

  return {
    create,
  }
}
