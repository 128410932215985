export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/home/runner/work/jpool-front-v2/jpool-front-v2/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/home/runner/work/jpool-front-v2/jpool-front-v2/src/pages/[...all].vue'),
    /* no children */
    meta: {
      "layout": "empty"
    }
  },
  {
    path: '/defi',
    name: '/defi',
    component: () => import('/home/runner/work/jpool-front-v2/jpool-front-v2/src/pages/defi.vue'),
    /* no children */
  },
  {
    path: '/direct-staking',
    /* internal name: '/direct-staking' */
    /* no component */
    children: [
      {
        path: '',
        name: '/direct-staking/',
        component: () => import('/home/runner/work/jpool-front-v2/jpool-front-v2/src/pages/direct-staking/index.vue'),
        /* no children */
      },
      {
        path: ':vote',
        /* internal name: '/direct-staking/[vote]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/direct-staking/[vote]/',
            component: () => import('/home/runner/work/jpool-front-v2/jpool-front-v2/src/pages/direct-staking/[vote]/index.vue'),
            /* no children */
          },
          {
            path: 'report',
            name: '/direct-staking/[vote]/report',
            component: () => import('/home/runner/work/jpool-front-v2/jpool-front-v2/src/pages/direct-staking/[vote]/report.vue'),
            /* no children */
          },
          {
            path: 'stake-report',
            name: '/direct-staking/[vote]/stake-report',
            component: () => import('/home/runner/work/jpool-front-v2/jpool-front-v2/src/pages/direct-staking/[vote]/stake-report.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/high-yield',
    name: '/high-yield',
    component: () => import('/home/runner/work/jpool-front-v2/jpool-front-v2/src/pages/high-yield.vue'),
    /* no children */
  },
  {
    path: '/holders-club',
    /* internal name: '/holders-club' */
    /* no component */
    children: [
      {
        path: '',
        name: '/holders-club/',
        component: () => import('/home/runner/work/jpool-front-v2/jpool-front-v2/src/pages/holders-club/index.vue'),
        /* no children */
        meta: {
          "layout": "holders-club"
        }
      },
      {
        path: 'about',
        name: '/holders-club/about',
        component: () => import('/home/runner/work/jpool-front-v2/jpool-front-v2/src/pages/holders-club/about.vue'),
        /* no children */
        meta: {
          "layout": "holders-club"
        }
      },
      {
        path: 'boosters',
        name: '/holders-club/boosters',
        component: () => import('/home/runner/work/jpool-front-v2/jpool-front-v2/src/pages/holders-club/boosters.vue'),
        /* no children */
        meta: {
          "layout": "holders-club"
        }
      },
      {
        path: 'more',
        name: '/holders-club/more',
        component: () => import('/home/runner/work/jpool-front-v2/jpool-front-v2/src/pages/holders-club/more.vue'),
        /* no children */
        meta: {
          "layout": "holders-club"
        }
      },
      {
        path: 'profile',
        name: '/holders-club/profile',
        component: () => import('/home/runner/work/jpool-front-v2/jpool-front-v2/src/pages/holders-club/profile.vue'),
        /* no children */
        meta: {
          "layout": "holders-club"
        }
      },
      {
        path: 'referrals',
        name: '/holders-club/referrals',
        component: () => import('/home/runner/work/jpool-front-v2/jpool-front-v2/src/pages/holders-club/referrals.vue'),
        /* no children */
        meta: {
          "layout": "holders-club"
        }
      },
      {
        path: 'rewards',
        name: '/holders-club/rewards',
        component: () => import('/home/runner/work/jpool-front-v2/jpool-front-v2/src/pages/holders-club/rewards.vue'),
        /* no children */
        meta: {
          "layout": "holders-club"
        }
      },
      {
        path: 'staking',
        name: '/holders-club/staking',
        component: () => import('/home/runner/work/jpool-front-v2/jpool-front-v2/src/pages/holders-club/staking.vue'),
        /* no children */
        meta: {
          "layout": "holders-club"
        }
      }
    ],
  },
  {
    path: '/pool-info',
    name: '/pool-info',
    component: () => import('/home/runner/work/jpool-front-v2/jpool-front-v2/src/pages/pool-info.vue'),
    /* no children */
  },
  {
    path: '/stake-concentration',
    name: '/stake-concentration',
    component: () => import('/home/runner/work/jpool-front-v2/jpool-front-v2/src/pages/stake-concentration.vue'),
    /* no children */
  },
  {
    path: '/stake-distribution',
    name: '/stake-distribution',
    component: () => import('/home/runner/work/jpool-front-v2/jpool-front-v2/src/pages/stake-distribution.vue'),
    /* no children */
  },
  {
    path: '/validator-profit-calculator',
    name: '/validator-profit-calculator',
    component: () => import('/home/runner/work/jpool-front-v2/jpool-front-v2/src/pages/validator-profit-calculator.vue'),
    /* no children */
  },
  {
    path: '/wormhole',
    name: '/wormhole',
    component: () => import('/home/runner/work/jpool-front-v2/jpool-front-v2/src/pages/wormhole.vue'),
    /* no children */
  }
]

export function handleHotUpdate(_router, _hotUpdateCallback) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
    import.meta.hot.data.router_hotUpdateCallback = _hotUpdateCallback
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    // call the hotUpdateCallback for custom updates
    import.meta.hot.data.router_hotUpdateCallback?.(mod.routes)
    const route = router.currentRoute.value
    router.replace({
      ...route,
      // NOTE: we should be able to just do ...route but the router
      // currently skips resolving and can give errors with renamed routes
      // so we explicitly set remove matched and name
      name: undefined,
      matched: undefined,
      force: true
    })
  })
}

