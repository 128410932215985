<script lang="ts" setup>
import jpointIcon from '~/assets/img/tokens/jpoints.svg'

const router = useRouter()

const path = computed(() => router.currentRoute.value.path)
const isHoldersClub = computed(() => path.value.includes('holders-club'))
</script>

<template>
  <router-link v-if="!isHoldersClub" to="/holders-club" class="top-banner">
    2X
    <img :src="jpointIcon" alt="jp icon">
    JPoints on your Stake in Holders Club!
  </router-link>
</template>

<style lang="scss">
.top-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $spacing-4;
  width: 100%;
  height: 40px;
  background: rgb(87, 49, 237);
  background: radial-gradient(circle, rgba(87, 49, 237, 1) 0%, rgba(17, 17, 17, 1) 100%);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
}
</style>
