<script lang="ts" setup>
import type { BButtonProps } from 'bootstrap-vue-next'

const {
  loading = false,
  ...props } = defineProps<{
    loading?: boolean
  } & BButtonProps>()
</script>

<template>
  <b-button v-bind="props" :loading="false">
    <div class="btn-content">
      <slot name="prepend" />
      <slot />
      <slot name="append" />
    </div>
    <transition name="fade">
      <div v-if="loading" class="loading-btn-spinner">
        <BSpinner />
      </div>
    </transition>
  </b-button>
</template>
