<script lang="ts" setup>
const { y } = useWindowScroll()

function handleScroll() {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}
</script>

<template>
  <transition name="fade">
    <j-btn v-if="y > 1000" variant="secondary" class="to-top-btn" @click="handleScroll">
      <i-app-arrow-up />
    </j-btn>
  </transition>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition:
    opacity 0.2s ease-in-out,
    transform 0.2s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
</style>

<style lang="scss">
.btn.to-top-btn {
  position: fixed;
  bottom: 100px;
  right: 30px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  padding: 0;

  @media (max-width: $breakpoint-md) {
    bottom: 172px;
    right: 22px;
  }

  @media (max-width: $breakpoint-xs) {
    bottom: 126px;
    right: 15px;
  }

  &:hover {
    opacity: 1;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}
</style>
