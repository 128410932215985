<script lang="ts" setup>
import feedbackIcon from '../assets/img/feedback.svg'
import FeedbackDialog from './FeedbackDialog.vue'

const emit = defineEmits(['redirect', 'firstOpen'])
const isFeedback = useLocalStorage('feedback', false)
const dialog = ref(false)

if (isClient) {
  watch(isFeedback, (f) => {
    if (!f) {
      setTimeout(() => {
        isFeedback.value = true
        dialog.value = true
        emit('firstOpen')
      }, 5000)
    }
  }, { immediate: true })
}
</script>

<template>
  <div class="feedback-btn" @click="dialog = true">
    <img :src="feedbackIcon" alt="feedback icon">
  </div>

  <feedback-dialog v-model="dialog" @redirect="emit('redirect')" />
</template>

<style lang="scss">
.feedback-btn {
  position: fixed;
  bottom: 30px;
  right: 18px;
  width: 64px;
  height: 64px;
  opacity: 0.6;
  cursor: pointer;
  transition: opacity 0.1s ease-in-out;

  &:hover {
    opacity: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: $breakpoint-md) {
    bottom: 100px;
    right: 10px;
  }

  @media (max-width: $breakpoint-xs) {
    width: 50px;
    height: 50px;
    bottom: 70px;
  }
}
</style>
