import type { Commitment } from '@solana/web3.js'
import type { Endpoint } from '~/store'

const MAIN_STAKE_POOL_ADDRESS = 'CtMyWsrUtAwXWiGr9WjHT5fC3p3fgV8cyGpLTo2LJzG1'
const MAIN_STAKE_LIMIT = 1_500_000

// const TEST_STAKE_POOL_ADDRESS = 'AeuEVJrnL5SwftWzchEfqMkKXPxLcZjrFtShdAZ7FwKy'
// const TEST_STAKE_LIMIT = 1500000

const DEV_STAKE_POOL_ADDRESS = 'vU5rGXWuLTqFbxtz89TXEbJ59wYHJiLHNmtbXdSB7UF'

export const ENDPOINTS: Endpoint[] = [
  {
    id: 'helius-mainnet',
    name: 'Helius RPC',
    cluster: 'mainnet-beta',
    url: 'https://marketa-1sh8m6-fast-mainnet.helius-rpc.com/',
    stakePoolAddress: MAIN_STAKE_POOL_ADDRESS,
    stakeLimit: MAIN_STAKE_LIMIT,
  },
  {
    id: 'quiknode-mainnet',
    name: 'Quiknode RPC',
    cluster: 'mainnet-beta',
    url: 'https://capable-muddy-scion.solana-mainnet.quiknode.pro/97a070e9492a153925c6d15e4ebd74592a2338c2/',
    stakePoolAddress: MAIN_STAKE_POOL_ADDRESS,
    stakeLimit: MAIN_STAKE_LIMIT,
  },
  // {
  //   id: 'mainnet-beta',
  //   name: 'Solana RPC',
  //   cluster: 'mainnet-beta',
  //   url: 'https://api.mainnet-beta.solana.com',
  //   stakePoolAddress: MAIN_STAKE_POOL_ADDRESS,
  //   stakeLimit: MAIN_STAKE_LIMIT,
  // },
  // app.jpool.one blocked (permanent 403) on clusterApiUrl('mainnet-beta')
  // {
  //   id: 'testnet',
  //   name: 'TestNet',
  //   cluster: 'testnet',
  //   url: 'https://testnet.rpcpool.com/',
  //   stakePoolAddress: TEST_STAKE_POOL_ADDRESS,
  //   stakeLimit: TEST_STAKE_LIMIT,
  // },
]

if (import.meta.env.DEV) {
  ENDPOINTS.push({
    id: 'devnet',
    name: 'DevNet',
    cluster: 'devnet',
    url: 'https://jody-hlb1qh-fast-devnet.helius-rpc.com/',
    stakePoolAddress: DEV_STAKE_POOL_ADDRESS,
    stakeLimit: 100_000,
  }, {
    id: 'localnet',
    name: 'LocalNet',
    cluster: 'localnet',
    url: 'http://127.0.0.1:8899',
    stakePoolAddress: 'HYgufSTxQ8Ma6qgzQ8n2vD5gPTB7YgP5JjurYCgQqFPR',
  })
}

export const DEFAULT_ENDPOINT = ENDPOINTS[0] as Endpoint

/**
 * The level of commitment desired when querying state
 * <pre>
 *   'processed': Query the most recent block which has reached 1 confirmation by the connected node
 *   'confirmed': Query the most recent block which has reached 1 confirmation by the cluster
 *   'finalized': Query the most recent block which has been finalized by the cluster
 * </pre>
 */
export const DEFAULT_COMMITMENT: Commitment = 'confirmed'

export const DEFAULT_MONITOR_COMMITMENT: Commitment = 'processed'

export const DEFAULT_SEND_TIMEOUT = 15_000

/**
 * Time to allow for the server to initially process a transaction (in milliseconds)
 */
export const DEFAULT_CONFIRM_TIMEOUT = 120_000
